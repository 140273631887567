import qs from 'qs'

import {API, MEDIA_HOST} from '../config/host'

export const requestData = (action, data, method) => {
    return(
        fetch(API+action, {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: qs.stringify(data)
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
    )
};

export const checkImage = (imagePath) => {
    return(
        fetch(MEDIA_HOST+imagePath, {
            method: 'HEAD',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            // body: qs.stringify(data)
        })
            .then(res => {
                console.log(res)
            }).catch(err => console.log('Error:', err))
    )
};

export const requestAuth = (action, api_token) => {
    return(
        fetch(API+action, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Authorization': 'bearer '+api_token
            },
            body: qs.stringify({ api_token: api_token })
        })
            .catch((error) => {
                console.error(error);
            })
            .then((response) => response.json())
    )
};
