import React from "react";
import qs from 'qs'

import { API, MAIN_HOST } from "../config/host";
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAHxQebxqAp9tjqOM8WG0hgOioX1tPi8P4",
    authDomain: "belanja-pasti.firebaseapp.com",
    databaseURL: "https://belanja-pasti.firebaseio.com",
    projectId: "belanja-pasti",
    storageBucket: "belanja-pasti.appspot.com",
    messagingSenderId: "478035489341",
    appId: "1:478035489341:web:acd5342518de6bcf",
    measurementId: "G-KJ5C52S377"
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

var chatTopic = '';
var merchantTopic = '';

(()=>{
    if (MAIN_HOST === 'http://103.107.85.79:8501/'){
        chatTopic = 'devel-cms-chat'
        merchantTopic = 'devel-merchant-chat'
    }else if (MAIN_HOST === 'http://localhost:8000/'){
        chatTopic = 'devel-cms-chat'
        merchantTopic = 'devel-merchant-chat'
    }else if(MAIN_HOST === 'https://belanjapasti.com/'){
        chatTopic = 'cms-chat'
        merchantTopic = 'merchant-chat'
    }
})()

export let requestFirebaseNotificationPermission = (api_token) =>
    new Promise(async (resolve, reject) => {
        const permission = await Notification.requestPermission();

        if (permission === "granted") {
            getToken(messaging, { vapidKey: "BDufqD4b8t6FwMppT_AQjXkm3P6pvEMRwlR3wkb8ZFGEjzxuOgGe6yo946JNJUSYmRT4cST3K2lrWkWX-uPspto" })
                .then((token) => {
                    if (token) {
                        subscriberToTopic(token, chatTopic, api_token);
                        subscriberToTopic(token, merchantTopic, api_token);

                        resolve(token);
                    } else {
                        console.error('No registration token available.');
                    }
                })
                .catch((err) => {
                    console.error('An error occurred while retrieving token. ', err);
                });
        } else {
            console.error("Unable to get permission to notify.");
        }
    });

export const onMessageListener = onMessage

export const subscriberToTopic = (token, topic, api_token) =>{
    fetch(API+'message-subcribe-topic', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': 'bearer '+api_token
        },
        body: qs.stringify({
            api_token: api_token,
            token_gcm: token,
            topic: topic
        })
    })
        .catch((error) => {
            console.error(error);
        })
        .then((response) => response.json())
}
